<script>
import Vue from 'vue'

import { ResourceListField, ResourceListAction } from '@sigmacloud/sigma-client/dist/components/core/resource_list'
// import { CheckRunResource, ReportParamResource } from '@sigmacloud/sigma-client/dist/resources'
// import { ReportCheck } from '@sigmacloud/sigma-client/dist/reports/checks'

export default Vue.extend({
    data() {
        return {
            listFields: [
                new ResourceListField('Check Number', 'number'),
                new ResourceListField('Check Amount', 'amount_decimal'),
                new ResourceListField('Bank', 'batch.bank.name'),
                new ResourceListField('Payee Name', 'payee_name'),
                new ResourceListField('Requested On', 'signature_requests.created_on'),
                new ResourceListField('Requestor', 'signature_requests.requested_by.username'),
                new ResourceListField('Signature Status', 'signature_status'),
            ],
            listActions: [
                new ResourceListAction('Sign Selected', {
                    async handler(checks, { userResource }) {
                        return await Promise.all(
                            checks.map(async (check) => {
                                let manager = await check.get('signature_requests')
                                await manager.resolve()

                                let signatureRequest = manager.resources.reduce((resultRequest, currentRequest) => {
                                    let sameUser = currentRequest.get('requested_user') === userResource.get('id')
                                    let newerRequest = currentRequest.get('created_on') > resultRequest.get('created_on')

                                    return sameUser && newerRequest ? currentRequest : resultRequest
                                })

                                let signatureId = signatureRequest.attributes.signature
                                signatureRequest.attributes.signature = null
                                signatureRequest.attributes.signature = signatureId

                                return [check.approve({ signature: signatureRequest.get('signature') }), signatureRequest.save()]
                            })
                        )
                    },
                    params: {
                        userResource: this.$store.getters.userResource,
                    },
                }),
                // new ResourceListAction('Print Selected', {
                //     async handler(resources) {
                //         let checkRun = new CheckRunResource({ checks: resources.map((check) => check.id) })
                //         let { resources: [{ id: checkRunId }] = [0] } = await checkRun.save()

                //         let reportParamRes = new ReportParamResource({ data: { check_run: checkRunId, report_template: 'blank_check_1sig' } })
                //         let { resources: [{ id: reportParamId }] = [0] } = await reportParamRes.save()

                //         console.log(reportParamId, ReportCheck)
                //         // let {
                //         //     data: { data: b64b },
                //         let results = await ReportCheck.detail({
                //             params: { cached_parameters: reportParamId, report_template: 'blank_check_1sig' },
                //         })
                //         console.log(results)

                //         // let randString = Math.random().toString(16).substr(2, 16)

                //         // console.log(b64b)

                //         // const downloadLink = document.createElement('a')
                //         // downloadLink.setAttribute('href', `data:application/pdf;base64,${b64b}`)
                //         // downloadLink.setAttribute('download', `check-${randString}.pdf`)
                //         // downloadLink.click()

                //         // return Promise.resolve()
                //     },
                // }),
            ],
        }
    },
})
</script>

<template>
    <resource-list
        ref="checkList"
        v-page-title="'Check Signatures'"
        :resource-class="$resources.CheckResource"
        :resolve-attributes="['batch.bank.currency.code', 'signature_requests']"
        :actions="listActions"
        :fields="listFields"
        page-getter-method="signable"
        title="Check Signatures">
        <template #value-amount_decimal="{ resource }">
            <resource-format
                display-template="amount_decimal"
                :resource="resource"
                :currency="resource.get('batch.bank.currency.code')"
                :append-text="' ' + resource.get('batch.bank.currency.code')" />
        </template>
    </resource-list>
</template>
